import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import BaseComponent from "components/BaseComponent";
import ExternalLinkButton from "components/buttons/ExternalLinkButton";
import SubmitButton from "components/buttons/SubmitButton";
import { Bike, BikeResult } from "types/Bike";
import Vendor from "types/Vendor";
import { Form, Modal } from "react-bootstrap";
import { VendorContext } from "context/VendorContext";

interface ViewBikeProps {
  selectedBike?: Bike;
  emailSent: boolean;
  sizingResults?: BikeResult;
}

interface FormValues {
  name: string;
  email: string;
}

const ViewBike = ({
  selectedBike,
  emailSent,
  sizingResults,
}: ViewBikeProps) => {
  let history = useHistory();

  const vendor = (useContext(VendorContext) as unknown) as Vendor;

  const purchaseLink = selectedBike ? selectedBike.purchaseLink : "/";
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const launchEmailModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!emailSent) {
      setShowEmailModal(true);
    } else {
      window.location.replace(purchaseLink);
    }
  };

  const handleCloseEmailModal = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setShowEmailModal(false);
  };

  const handleCloseSuccessModal = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setShowSuccessModal(false);
  };

  const handleSubmitEmail = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const emailUrl = process.env.REACT_APP_EMAIL_URL || "";
    if (emailUrl === "") {
      console.error("Email URL not defined");
    }
    fetch(emailUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formValues.name,
        email: formValues.email,
        results: sizingResults,
      }),
    })
      .then(() => {
        console.log("Success:");
        setShowEmailModal(false);
        setShowSuccessModal(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const buttonBottom = SubmitButton({
    label: vendor && vendor.strings.viewBike.button,
    handleSubmit: launchEmailModal,
  });

  const buttonModalBottom = ExternalLinkButton({
    label: vendor && vendor.strings.emailModal2Button.buttonBottom,
    link: purchaseLink,
    inverted: true,
  });

  const buttonSuccessTop = ExternalLinkButton({
    label: vendor && vendor.strings.emailModal2Button.buttonSuccessTop,
    link: purchaseLink,
  });

  const buttonSuccessBottom = SubmitButton({
    label: vendor && vendor.strings.emailModal2Button.buttonSuccessBottom,
    handleSubmit: handleCloseSuccessModal,
    inverted: true,
  });

  const DisplaySuccess = () => {
    return (
      vendor && (
        <div className="emailSentWrapper">
          <div className="emailResultsTextWrapper">
            <div className="emailResultsEmojiWrapper">
              <span role="img" aria-label="ta-da">
                {vendor.strings.emailModal2Button.emoji}
              </span>
            </div>
            {vendor.strings.emailModal2Button.buttonSuccessTitle}
          </div>
        </div>
      )
    );
  };

  return (
    vendor && (
      <BaseComponent button1={buttonBottom}>
        <div className="titleContainer">
          <div className="headlineAndButtonContainer">
            <button
              type="button"
              className="backbutton"
              onClick={() => {
                history.goBack();
              }}
            >
              <div className="caretBack" />
              <div className="subTitle">{vendor.strings.viewBike.title}</div>
            </button>
          </div>
        </div>
        {selectedBike && (
          <div className="bikeNameAndImageContainer">
            <img
              src={selectedBike.image}
              className="bikeResultImage"
              alt="selected bike"
            />
            <div className="viewBikeName">{selectedBike.name}</div>
          </div>
        )}
        {selectedBike && (
          <div className="textLeftWrapper bodyText">
            <div className="bikePriceContainer">{selectedBike.price}</div>
            <div className="viewBikeContentWrapper">
              <div className="bikeTagline">
                {vendor.strings.viewBike.whyGreat}
              </div>
              <div className="bikeResultsList bodyTextColor">
                <ul>
                  {selectedBike.reasonsToBuy.map((sentence, index) => (
                    <li key={index}>{sentence}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        <Modal
          id="emailForm"
          show={showEmailModal}
          onHide={handleCloseEmailModal}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Title>{vendor.strings.emailModal2Button.title}</Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmitEmail}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  name="name"
                  required={true}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  required={true}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <input
                type="submit"
                value={vendor.strings.emailModal2Button.buttonTop}
              />
            </Form>
            <div className="underFormButtonWrapper">{buttonModalBottom}</div>
          </Modal.Body>
        </Modal>
        <Modal
          id="emailSent"
          show={showSuccessModal}
          onHide={handleCloseSuccessModal}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header />
          <Modal.Body>
            <DisplaySuccess />
            {buttonSuccessTop}
            <div className="dualButtonsDivider">{buttonSuccessBottom}</div>
          </Modal.Body>
        </Modal>
      </BaseComponent>
    )
  );
};

export default ViewBike;
