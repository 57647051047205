import React from "react";

const ONE_FOOT_IN_INCHES = 12;
const FEET_VALUES = [4, 5, 6];

type Props = {
  heightInputRef: React.MutableRefObject<HTMLSelectElement | null>;
};

type HeightOption = {
  value: number;
  label: string;
};

const options: HeightOption[] = [];
FEET_VALUES.forEach((foot) => {
  const startInch = foot === 4 ? 6 : 0;

  for (let index = startInch; index < ONE_FOOT_IN_INCHES; index++) {
    if (foot === 6 && index > 6) {
      return;
    }

    options.push({
      value: foot * 12 + index,
      label: `${foot}' ${index}"`,
    });
  }
});

const HeightInput = ({ heightInputRef }: Props) => {
  return (
    <div className="demoFormItem">
      <select
        className="demoFormSelect"
        defaultValue={"placeholder"}
        ref={heightInputRef}
      >
        <option value="placeholder" disabled hidden>
          How tall are you?
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HeightInput;
