import React, { ReactNode } from "react";
import UncontrolledLottie from "components/lotties/UncontrolledLottie";

interface LoadingComponentProps {
  children?: ReactNode;
}

const LoadingOverlay = ({ children }: LoadingComponentProps) => {
  return (
    <div className="loadingContainer">
      <div className="loadingIcon">
        <UncontrolledLottie />
        {children}
      </div>
    </div>
  );
};

export default LoadingOverlay;
