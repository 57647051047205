import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import Form from "react-bootstrap/Form";

interface FormValues {
  password: string;
}

const Login = ({ vendorParam }) => {
  const COOKIE_NAME = `kokoAuthentication_${vendorParam}`;
  const COOKIE_VALUE_KEY = `REACT_APP_COOKIE_${vendorParam.toUpperCase()}`;
  const history = useHistory();
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);

  const [formValues, setFormValues] = useState<FormValues>({
    password: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordIncorrect(false);
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const redirectUrl = new URLSearchParams(document.location.search).get(
    "redirect"
  );

  const ErrorMessage = () => {
    return <div className="passwordFeedback">Incorrect password</div>;
  };

  const handleSubmitAuth = (e: React.FormEvent<HTMLFormElement>): void => {
    Cookies.remove(COOKIE_NAME);
    e.preventDefault();
    fetch(process.env.REACT_APP_VENDOR_LOGIN || "", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: formValues.password,
        vendor: vendorParam,
      }),
    })
      .then((res) => res.json())
      .then(
        (response) => {
          if (response.success) {
            Cookies.set(COOKIE_NAME, process.env[COOKIE_VALUE_KEY] || "true");
            history.push(redirectUrl || "/howitworks");
          } else {
            setPasswordIncorrect(true);
          }
        },
        (error) => {
          console.log(`error: ${error}`);
          history.push("/login");
        }
      );
  };

  return (
    <Form onSubmit={handleSubmitAuth}>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox"></Form.Group>
      <input type="submit" value="Login" />
      {passwordIncorrect && <ErrorMessage />}
    </Form>
  );
};

export default Login;
