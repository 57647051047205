import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { VendorContext } from "context/VendorContext";
import "App.css";
import BaseComponent from "components/BaseComponent";
import PhotoButton from "components/buttons/PhotoButton";
import SubmitButton from "components/buttons/SubmitButton";
import { BikeResult } from "types/Bike";
import LoadingOverlay from "./LoadingOverlay";
import Vendor from "types/Vendor";

interface ReviewPictureProps {
  onPhotoUpload: (e: ChangeEvent<HTMLInputElement>) => boolean;
  onResults: (results: BikeResult) => void;
  photoFile?: File;
}

interface PhotoPayload {
  vendor: string;
  photoBase64: string;
}

const ReviewPicture = ({
  onPhotoUpload,
  onResults,
  photoFile,
}: ReviewPictureProps) => {
  const history = useHistory();
  const reader = new FileReader();
  const vendor = (useContext(VendorContext) as unknown) as Vendor;
  const vendorName = vendor && vendor.name;

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!onPhotoUpload(e)) {
      history.push(`/error?vendor=${vendorName}`);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (photoFile) {
      setIsLoading(true);
      const photoConverted = reader.result as string;
      const bikeUrl = process.env.REACT_APP_BIKE_LOOKUP_URL || "";
      if (bikeUrl === "") {
        console.error("Bike lookup URL not defined");
      }
      const photoPayload: PhotoPayload = {
        vendor: vendorName,
        photoBase64: photoConverted,
      };
      setIsLoading(true);

      fetch(bikeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(photoPayload),
      })
        .then((res) => res.json())
        .then(
          (response) => {
            setIsLoading(false);
            if (!response.success) {
              console.error(response.error);
              history.push(`/error?vendor=${vendorName}`);
            } else if (response) {
              onResults(response.results);
              history.push(`/showbikematch?vendor=${vendorName}`);
            } else {
              history.push(`/error?vendor=${vendorName}`);
            }
          },
          (error) => {
            // koko backend fails to connect
            setIsLoading(false);
            history.push(`/error?vendor=${vendorName}`);
          }
        );
    }
  };

  useEffect(() => {}, [isLoading]);

  const buttonPhoto = PhotoButton({
    label: vendor && vendor.strings.reviewPhoto.buttonRetake,
    onPhotoUpload: handlePhotoUpload,
  });

  const buttonLink = SubmitButton({
    label: vendor && vendor.strings.reviewPhoto.buttonSubmit,
    handleSubmit: handleSubmit,
  });

  const convertFile = (file: File) => {
    reader.readAsDataURL(file);
  };

  const ImagePreview = () => {
    let imageSource = "";
    if (photoFile) {
      imageSource = URL.createObjectURL(photoFile);
    }

    return <img src={imageSource} className="fullWidthImage" alt="Test" />;
  };

  const ListItems = () => {
    return (
      <ul className="reviewPictureList bodyTextColor">
        {vendor &&
          vendor.strings.reviewPhoto.checklist.map((sentence, index) => (
            <li key={index}>{sentence}</li>
          ))}
      </ul>
    );
  };

  // start the conversion to base64 in anticipation of the API call
  if (photoFile) {
    convertFile(photoFile);
  }

  return (
    vendor && (
      <BaseComponent button1={buttonPhoto} button2={buttonLink}>
        <div className="titleContainerLessPadding">
          <div className="headlineAndButtonContainer">
            <div className="title">{vendor.strings.reviewPhoto.title}</div>
          </div>
        </div>
        <div className="textLeftWrapper bodyText">
          <div className="whatCard">
            <strong>{vendor.strings.reviewPhoto.checkTitle}</strong>
            <ListItems />
            <div className="bodyTextColor">
              {vendor.strings.reviewPhoto.confirmOrRetake}
            </div>
          </div>
        </div>
        <ImagePreview />
        {isLoading && <LoadingOverlay />}
      </BaseComponent>
    )
  );
};

export default ReviewPicture;
