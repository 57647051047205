import React from "react";
import FrameSizingResponse from "types/FrameSizingResponse";

const DemoResults: React.FC<{ frameRecommendation: FrameSizingResponse }> = (
  props
) => {
  const {
    saddle_height_mm,
    crank_length_mm,
    recommendation: { barWidth, make, model, image, size, stem },
  } = props.frameRecommendation;

  return (
    <div>
      <div className="demoResultsContainer">
        <div className="">
          {make && (<div>Make: {make}</div>)}
          {model && (<div>Model: {model}</div>)}
          {size && (<div>Size: {size}</div>)}
          {saddle_height_mm && (<div>Saddle Height: {saddle_height_mm.toFixed(0)}</div>)}
          {stem && (<div>Stem Length: {stem}</div>)}
          {barWidth && (<div>Bar Width: {barWidth}</div>)}
          {crank_length_mm && (<div>Crank Length: {crank_length_mm}</div>)}
        </div>
        <img src={image} className="bikeResultImage" alt="selected bike" />
      </div>
    </div>
  );
};

export default DemoResults;
