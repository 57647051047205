import React, { useContext } from "react";
import BaseComponent from "components/BaseComponent";
import { useHistory } from "react-router-dom";

import LinkButton from "components/buttons/LinkButton";
import { VendorContext } from "context/VendorContext";
import Vendor from "types/Vendor";

interface ListItemProps {
  items: string[];
  badItems?: string[];
}

const ListItems = ({ items, badItems }: ListItemProps) => {
  return (
    <ul>
      {items.map((sentence, index) => (
        <li key={index}>{sentence}</li>
      ))}
      {badItems?.map((sentence, index) => (
        <li className="badCard" key={index}>
          {sentence}
        </li>
      ))}
    </ul>
  );
};

const GetCard = () => {
  const history = useHistory();
  const vendor = (useContext(VendorContext) as unknown) as Vendor;

  const button = LinkButton({
    label: vendor && vendor.strings.getCard.button,
    link: "/photoinstructions",
  });

  return (
    vendor && (
      <BaseComponent button1={button}>
        <div className="titleContainer">
          <div className="headlineAndButtonContainer">
            <button
              type="button"
              className="backbutton"
              onClick={() => {
                history.goBack();
              }}
            >
              <div className="caretBack" />
              <div className="title">{vendor.strings.getCard.title}</div>
            </button>
          </div>
          <div
            className="subTitle"
            dangerouslySetInnerHTML={{
              __html: vendor.strings.getCard.subtitle,
            }}
          ></div>
        </div>
        <img
          src={vendor.images.getCard}
          className="fullWidthImage"
          alt="personWithCreditCard"
        />
        <div className="textLeftWrapper bodyText">
          <div
            className="whatCard"
            dangerouslySetInnerHTML={{
              __html: vendor.strings.getCard.whatCard,
            }}
          ></div>
          <div className="cardOptionsList sideBySideListWrapper">
            <ListItems
              items={vendor.strings.getCard.cardExamples.slice(0, 3)}
            />
            <ListItems
              items={vendor.strings.getCard.cardExamples.slice(-2)}
              badItems={vendor.strings.getCard.badCardExamples}
            />
          </div>
          <div className="bodyTextColor">
            We recommend hiding sensitive information
          </div>
          <div className="whyCard">
            <div className="whyQuestionWrapper">
              <strong>{vendor.strings.getCard.whyCard}</strong>
            </div>
            <div className="bodyTextColor">
              {vendor.strings.getCard.whyCardAnswer}
            </div>
          </div>
        </div>
      </BaseComponent>
    )
  );
};

export default GetCard;
