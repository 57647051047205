import React, { ChangeEvent, useContext } from "react";

import BaseComponent from "components/BaseComponent";
import { useHistory } from "react-router-dom";
import PhotoButton from "components/buttons/PhotoButton";
import { VendorContext } from "context/VendorContext";
import Vendor from "types/Vendor";

interface PhotoInstructionsProps {
  onPhotoUpload: (e: ChangeEvent<HTMLInputElement>) => boolean;
}

const PhotoInstructions = ({ onPhotoUpload }: PhotoInstructionsProps) => {
  const history = useHistory();

  const vendor = (useContext(VendorContext) as unknown) as Vendor;
  const vendorName = vendor && vendor.name;

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onPhotoUpload(e)) {
      history.push(`/reviewpicture?vendor=${vendorName}`);
    } else {
      // TODO: If we have file upload issue, custom error page or same one we use for photo analysis errors?
      history.push(`/error?vendor=${vendorName}`);
    }
  };
  const button = PhotoButton({
    label: vendor && vendor.strings.photoInstructions.button,
    onPhotoUpload: handlePhotoUpload,
  });

  return (
    vendor && (
      <BaseComponent button1={button}>
        <div className="titleContainer">
          <div className="headlineAndButtonContainer">
            <button
              type="button"
              className="backbutton"
              onClick={() => {
                history.goBack();
              }}
            >
              <div className="caretBack" />
              <div className="title">
                {vendor.strings.photoInstructions.title}
              </div>
            </button>
          </div>
        </div>
        <img
          src={vendor.images.photoInstructions}
          className="photoInstructionsImage"
          alt="t-pose"
        />
        <div className="textLeftWrapper bodyText">
          <div className="secondHalfContentWrapper bodyTextColor">
            {vendor.strings.photoInstructions.bodyText}
          </div>
          <div className="secondHalfContentWrapper">
            <strong>{vendor.strings.photoInstructions.boldedText}</strong>
          </div>
        </div>
      </BaseComponent>
    )
  );
};

export default PhotoInstructions;
