import React from "react";
import { Style } from "types/FrameSizingRequest";

type Props = {
  riderStyleInputRef: React.MutableRefObject<HTMLSelectElement | null>;
};

const RiderStyleInput = ({ riderStyleInputRef }: Props) => {
  return (
    <div className="demoFormItem">
      <select
        className="demoFormSelect"
        defaultValue={"placeholder"}
        ref={riderStyleInputRef}
      >
        <option value="placeholder" disabled hidden>
          What type of rider are you?
        </option>
        <option value={Style.Endurance}>Recreational</option>
        <option value={Style.Competitive}>Racer</option>
      </select>
    </div>
  );
};

export default RiderStyleInput;
