import React, { ChangeEvent, useContext } from "react";
import { useHistory } from "react-router-dom";
import BaseComponent from "components/BaseComponent";
import errorIcon from "assets/error_outline.svg";
import PhotoButton from "components/buttons/PhotoButton";
import { VendorContext } from "context/VendorContext";
import Vendor from "types/Vendor";

interface PhotoErrorProps {
  onPhotoUpload: (e: ChangeEvent<HTMLInputElement>) => boolean;
  photoFile?: File;
}

interface ListItemProps {
  items: string[];
}

const ListItems = ({ items }: ListItemProps) => {
  return (
    <div>
      {items.map((sentence, index) => (
        <div key={index}>{sentence}</div>
      ))}
    </div>
  );
};

const PhotoError = ({ onPhotoUpload, photoFile }: PhotoErrorProps) => {
  const history = useHistory();

  const vendor = (useContext(VendorContext) as unknown) as Vendor;
  const vendorName = vendor && vendor.name;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onPhotoUpload(e)) {
      history.push(`/reviewpicture?vendor=${vendorName}`);
    } else {
      history.push(`/error?vendor=${vendorName}`);
    }
  };

  const ImagePreview = () => {
    let imageSource = "";
    if (photoFile) {
      imageSource = URL.createObjectURL(photoFile);
    }

    return <img src={imageSource} className="fullWidthImage" alt="Test" />;
  };

  const buttonPhoto = PhotoButton({
    label: vendor && vendor.strings.errorPage.button,
    onPhotoUpload: handleOnChange,
  });

  return (
    vendor && (
      <BaseComponent button1={buttonPhoto}>
        <div className="titleContainerLessPadding">
          <div className="headlineAndButtonContainer">
            <button
              type="button"
              className="backbutton"
              onClick={() => {
                history.goBack();
              }}
            >
              <div className="caretBack"></div>
              <div className="title">{vendor.strings.errorPage.title}</div>
            </button>
          </div>
          <div className="subTitle">{vendor.strings.errorPage.subtitle}</div>
        </div>
        <div className="textLeftWrapper bodyText">
          <div className="errorCard">
            <div className="errorMessageContainer">
              <div className="errorMessageTextWrapper">
                <div className="errorQuestionWrapper">
                  <img src={errorIcon} className="errorIcon" alt="Error" />
                  <strong>{vendor.strings.errorPage.errorPrompt}</strong>
                </div>
                <ListItems items={vendor.strings.errorPage.errorReasons} />
                {/* TODO: these will need to come from the backend response error codes */}
              </div>
            </div>
          </div>
        </div>
        <ImagePreview />
      </BaseComponent>
    )
  );
};

export default PhotoError;
