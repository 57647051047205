import React from "react";
import "App.css";
import { Link } from "react-router-dom";

interface LinkButtonProps {
  label: string;
  link: string;
  onClick?: () => void;
}

const LinkButton = ({ label, link, onClick }: LinkButtonProps) => {
  return (
    <Link to={link || "/"}>
      <button className="submitButton" onClick={onClick}>
        {label}
      </button>
    </Link>
  );
};

export default LinkButton;
