import React, {
  ChangeEvent,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";

import LoadingOverlay from "components/LoadingOverlay";
import FrameSizingRequest, { Style } from "types/FrameSizingRequest";
import DemoResults from "components/DemoResults";
import imageCompression from "browser-image-compression";
import BaseComponent from "components/BaseComponent";
import SubmitButton from "components/buttons/SubmitButton";
import HeightInput from "components/HeightInput";
import RiderStyleInput from "components/RiderStyleInput";
import { VendorContext } from "context/VendorContext";
import Vendor from "types/Vendor";
import VendorInput from "./VendorInput";

interface PhotoPayload {
  height: number;
  photoBase64: string;
}

const DemoForm = () => {
  const vendor = (useContext(VendorContext) as unknown) as Vendor;
  const reader = new FileReader();

  const [isLoading, setIsLoading] = useState(false);
  const [processingImage, setProcessingImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [photoURL, setPhotoURL] = useState<string | null>(null);
  const [frameRecommendation, setFrameRecommendation] = useState(null);

  const photoBase64 = useRef<string | null>(null);

  const heightInputRef = useRef<HTMLSelectElement>(null);
  const riderStyleInputRef = useRef<HTMLSelectElement>(null);
  const photoInputRef = useRef<HTMLInputElement>(null);
  const vendorInputRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (frameRecommendation !== null) {
      photoInputRef.current!.value = "";
      setPhotoURL(null);
    }
  }, [frameRecommendation]);

  useEffect(() => {
    if (photoURL !== null) {
      setFrameRecommendation(null);
      setErrorMessage(null);
    }
  }, [photoURL]);

  const handleUploadError = () => {
    setErrorMessage("There was an issue with that file, please try again.");
    setProcessingImage(false);
  };

  const handlePhotoUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        setPhotoURL(URL.createObjectURL(file));
        setProcessingImage(true);
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 6,
          maxWidthOrHeight: 1024,
        });

        // Get the base64 encoded file
        reader.onload = () => {
          photoBase64.current = reader.result as string;
          setProcessingImage(false);
        };
        reader.onerror = handleUploadError;
        reader.readAsDataURL(compressedFile);
      } else {
        throw new Error("error getting image");
      }
    } catch (error) {
      handleUploadError();
    }
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault();
    if (photoBase64?.current !== null) {
      setIsLoading(true);
      setErrorMessage(null);
      const bikeUrl = process.env.REACT_APP_FRAME_SIZE_URL || "";
      if (bikeUrl === "") {
        console.error("Bike lookup URL not defined");
      }

      const payload: FrameSizingRequest = {
        height_in: parseInt(heightInputRef.current?.value || "0"),
        image: photoBase64.current,
        make: vendor.name === "koko" ? vendorInputRef.current?.value || "generic" : vendor.name,
        style: (riderStyleInputRef.current?.value as Style) || Style.Endurance,
      };

      try {
        const response = await fetch(bikeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        const jsonResponse = await response.json();

        if (jsonResponse.results) {
          setFrameRecommendation(jsonResponse.results);
        } else {
          throw new Error("error with response");
        }
      } catch (error) {
        setErrorMessage("Sorry there was error getting frame sizing results.");
      }

      setIsLoading(false);
    }
  };

  const GetResultsButton = SubmitButton({
    label: "Get Results",
    handleSubmit: handleSubmit,
  });

  return (
    <BaseComponent button1={GetResultsButton}>
      <div className="demoContainer">
        <HeightInput heightInputRef={heightInputRef} />
        <RiderStyleInput riderStyleInputRef={riderStyleInputRef} />
        {vendor && vendor.name === 'koko' && <VendorInput vendorInputRef={vendorInputRef} /> }
        <div className="photoButton">
          <input
            name="file"
            id="file"
            type="file"
            accept="image/*"
            className="inputFile"
            onChange={handlePhotoUpload}
            onClick={() => setErrorMessage(null)}
            ref={photoInputRef}
          />
          <label htmlFor="file">Pick Photo</label>
        </div>
        {errorMessage && <div>{errorMessage}</div>}
        {photoURL && !frameRecommendation && (
          <img src={photoURL!} className="fullWidthImage" alt="Test" />
        )}
        {frameRecommendation && (
          <DemoResults frameRecommendation={frameRecommendation!} />
        )}
      </div>
      {isLoading && <LoadingOverlay />}
      {processingImage && (
        <LoadingOverlay children={<div>Processing Photo...</div>} />
      )}
    </BaseComponent>
  );
};

export default DemoForm;
