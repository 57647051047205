import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";

import Cookies from "js-cookie";

const ProtectedRoute = ({ path, children, vendorParam }) => {
  const COOKIE_VALUE_KEY = `REACT_APP_COOKIE_${vendorParam.toUpperCase()}`;
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (!isAuthenticated) {
    const cookie = process.env[COOKIE_VALUE_KEY] || "true";
    if (Cookies.get(`kokoAuthentication_${vendorParam}`) === cookie) {
      setIsAuthenticated(true);
    }
  }

  return (
    <Route>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: `/login?redirect=${path}`,
            search: `?vendor=${vendorParam}`,
          }}
        />
      )}
    </Route>
  );
};

export default ProtectedRoute;
