export enum Style {
  Competitive = "competitive",
  Endurance = "endurance",
}

export enum Vendors {
  ventum = "Ventum",
  generic = "Generic",
  factor = "Factor",
  time = "Time",
  canyon = "Canyon",
}

export default interface FrameSizingRequest {
  height_in: number;
  image: string;
  make?: string;
  model?: string;
  style?: Style;
}
