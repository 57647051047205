import React, { useContext, useState } from "react";
import BaseComponent from "components/BaseComponent";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import LinkButton from "components/buttons/LinkButton";
import SubmitButton from "components/buttons/SubmitButton";
import { Bike, BikeResult } from "types/Bike";
import { VendorContext } from "context/VendorContext";
import Vendor from "types/Vendor";

interface BikeResultClickable {
  bike: Bike;
  onClick: () => void;
}

interface FormValues {
  name: string;
  email: string;
}

interface ShowBikeMatchProps {
  sizingResults?: BikeResult;
  handleBikeSelection: (bike: Bike) => void;
  handleEmailSent: (sent: boolean) => void;
}

const BikeMatchResult = ({ bike, onClick }: BikeResultClickable) => {
  const vendor = (useContext(VendorContext) as unknown) as Vendor;
  const vendorName = vendor && vendor.name;
  const button = LinkButton({
    label: "View bike",
    link: `/viewbike?vendor=${vendorName}`,
    onClick: onClick,
  });

  return (
    <div className="resultsContainer">
      <div className="resultsTagline">
        <strong>{bike.tagline}</strong>
      </div>
      <img src={bike.image} className="fullWidthImage" alt="Bike Match" />
      <div className="resultsNameAndPrice">
        <div>{bike.name}</div>
        <div>{bike.price}</div>
      </div>
      <div className="resultsButton bikeMatchButton">{button}</div>
    </div>
  );
};

const ShowBikeMatch = ({
  sizingResults,
  handleBikeSelection,
  handleEmailSent,
}: ShowBikeMatchProps) => {
  const vendor = (useContext(VendorContext) as unknown) as Vendor;

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleShowEmailForm = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setShowSuccessModal(false);
    setShowEmailModal(true);
  };

  const handleSubmitEmail = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const emailUrl = process.env.REACT_APP_EMAIL_URL || "";
    if (emailUrl === "") {
      console.error("Email URL not defined");
    }
    fetch(emailUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formValues.name,
        email: formValues.email,
        results: sizingResults,
      }),
    })
      .then(() => {
        console.log("Success:");
        setShowSuccessModal(true);
        handleEmailSent(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setShowEmailModal(false);
  };

  const showEmailModalButton = SubmitButton({
    label: vendor && vendor.strings.matchResults.button,
    handleSubmit: handleShowEmailForm,
  });

  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormValues({
      ...formValues,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const bikeMatchResults =
    sizingResults &&
    sizingResults.bikes.map((result, i) => {
      const selectBike: Bike = {
        tagline: result.tagline,
        image: result.image,
        name: result.name,
        bikeStats: result.bikeStats,
        personStats: result.personStats,
        price: result.price,
        reasonsToBuy: result.reasonsToBuy,
        purchaseLink: result.purchaseLink,
      };
      const makeBikeSelection = () => {
        handleBikeSelection(selectBike);
      };

      return (
        <BikeMatchResult
          key={i}
          bike={selectBike}
          onClick={makeBikeSelection}
        />
      );
    });

  const DisplaySuccess = () => {
    return (
      vendor && (
        <div className="emailSentWrapper">
          <div className="emailResultsTextWrapper">
            <div className="emailResultsEmojiWrapper">
              <span role="img" aria-label="ta-da">
                {vendor.strings.emailModal.emoji}
              </span>
            </div>
            {vendor.strings.emailModal.success}
          </div>
          <div className="underFormButtonWrapper">
            <button className="submitButton" onClick={handleClose}>
              {vendor.strings.emailModal.successConfirm}
            </button>
          </div>
        </div>
      )
    );
  };

  return (
    vendor && (
      <BaseComponent button1={showEmailModalButton}>
        <div className="titleContainerNoBackButton">
          <div className="title">{vendor.strings.matchResults.title}</div>
        </div>
        {bikeMatchResults}

        <Modal
          id="emailForm"
          show={showEmailModal}
          onHide={handleClose}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            {!showSuccessModal && (
              <Form onSubmit={handleSubmitEmail}>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="name"
                    name="name"
                    required={true}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    required={true}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <input
                  type="submit"
                  value={vendor.strings.emailModal.submitButton}
                />
              </Form>
            )}
            {showSuccessModal && <DisplaySuccess />}
          </Modal.Body>
        </Modal>
      </BaseComponent>
    )
  );
};

export default ShowBikeMatch;
