import React from "react";
import "App.css";

interface ExternalLinkButtonProps {
  label: string;
  link: string;
  inverted?: boolean;
  onClick?: () => void;
}

const ExternalLinkButton = ({
  label,
  link,
  inverted,
  onClick,
}: ExternalLinkButtonProps) => {
  return (
    <div
      className={`${inverted ? "externalLinkButtonInverted" : ""} noUnderline`}
    >
      <a href={link || "/"} target="_blank" rel = "noopener noreferrer">
        <button
          className={`${inverted ? "submitButtonInverted" : "submitButton"}`}
          onClick={onClick}
        >
          {label}
        </button>
      </a>
    </div>
  );
};

export default ExternalLinkButton;
