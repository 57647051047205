import React, { ReactNode, useContext } from "react";
import Vendor from "types/Vendor";
import "App.css";
import { VendorContext } from "context/VendorContext";
import footerLogo from "assets/IOG_Logo.svg";

interface BaseComponentProps {
  button1?: ReactNode;
  button2?: ReactNode;
  children: ReactNode;
}

const BaseComponent = ({ button1, button2, children }: BaseComponentProps) => {
  const vendor = (useContext(VendorContext) as unknown) as Vendor;

  return (
    vendor && (
      <div className="container">
        <header id="header">
          <img src={vendor.images.logo} alt="logo" />
        </header>
        <div className="content">{children}</div>
        <footer id="footer" className="footer shadowTop">
          <div
            className={`${
              button2 ? "dualButtonsWrapper" : ""
            } footerButtonWrapper`}
          >
            {button1}
            {button2}
          </div>
          <div className="footerTagline">
            Powered By
            <img src={footerLogo} alt="IOG" />
          </div>
        </footer>
      </div>
    )
  );
};

export default BaseComponent;
