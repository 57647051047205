import React, { ChangeEvent } from "react";
import "App.css";

interface PhotoButtonProps {
  label: string;
  onPhotoUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PhotoButton = ({ label, onPhotoUpload }: PhotoButtonProps) => {
  return (
    <div className="photoButton">
      <input
        name="file"
        id="file"
        type="file"
        accept="image/*"
        className="inputFile"
        onChange={onPhotoUpload}
      />
      <label htmlFor="file">{label}</label>
    </div>
  );
};

export default PhotoButton;
