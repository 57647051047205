import React from "react";
import { Vendors } from "types/FrameSizingRequest";

type Props = {
  vendorInputRef: React.MutableRefObject<HTMLSelectElement | null>;
};

const VendorInput = ({ vendorInputRef }: Props) => {
  return (
    <div className="demoFormItem">
      <select
        className="demoFormSelect"
        ref={vendorInputRef}
      >
        {Object.entries(Vendors).map(([key, label]) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default VendorInput;
