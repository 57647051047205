import React, { useContext, useState } from "react";

import BaseComponent from "components/BaseComponent";
import LinkButton from "components/buttons/LinkButton";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Checkmark from "assets/checkmark.svg";
import { VendorContext } from "context/VendorContext";
import Vendor from "types/Vendor";

interface DropdownItem {
  title: string;
  body: string;
}
interface ListItemProps {
  items: DropdownItem[];
}

const Dropdowns = ({ items }: ListItemProps) => {
  const [activeDropdown, setActiveDropdown] = useState<Number>();

  const handleDropdownClick = (num: Number): void => {
    if (activeDropdown === num) {
      setActiveDropdown(-1);
    } else {
      setActiveDropdown(num);
    }
  };

  return (
    <div className="dropdowns">
      <Accordion id="accordion" defaultActiveKey="">
        {items.map((data, index) => (
          <Card key={index}>
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={index.toString()}
              onClick={() => handleDropdownClick(index)}
            >
              <img src={Checkmark} alt="checkmark" className="checkmark"></img>{" "}
              {data.title}
              <div
                className={`${
                  activeDropdown === index ? "open" : "closed"
                } caretIcon`}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body>{data.body}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};

const HowItWorks = () => {
  const vendor = (useContext(VendorContext) as unknown) as Vendor;

  const button = LinkButton({
    label: vendor && vendor.strings.howItWorks.button,
    link: "/getcard",
  });

  return (
    vendor && (
      <BaseComponent button1={button}>
        <div className="titleContainerNoBackButton">
          <div className="title">{vendor.strings.howItWorks.title}</div>
        </div>
        <img
          src={vendor.images.howItWorks}
          className="fullWidthImage"
          alt="personRidingBike"
        />
        <Dropdowns items={vendor.strings.howItWorks.worksBullets} />
      </BaseComponent>
    )
  );
};

export default HowItWorks;
