import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";

import ProtectedRoute from "components/authentication/ProtectedRoute";
import Login from "components/Login";
import HowItWorks from "components/HowItWorks";
import GetCard from "components/GetCard";
import PhotoError from "components/PhotoError";
import PhotoInstructions from "components/PhotoInstructions";
import ReviewPicture from "components/ReviewPicture";
import ShowBikeMatch from "components/ShowBikeMatch";
import ViewBike from "components/ViewBike";
import { VendorContext } from "context/VendorContext";
import { Bike, BikeResult } from "types/Bike";
import { Theme } from "types/Vendor";
import DemoForm from "components/DemoForm";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const setCSSVariables = (theme: Theme) => {
  for (const property in theme) {
    document.documentElement.style.setProperty(
      `--${property}`,
      theme[property]
    );
  }
};

const App = () => {
  const [photoFile, setPhotoFile] = useState<File>();
  const [sizingResults, setSizingResults] = useState<BikeResult>();
  const [selectedBike, setSelectedBike] = useState<Bike>();
  const [emailSent, setEmailSent] = useState(false);

  const DEFAULT_VENDOR = process.env.REACT_APP_DEFAULT_VENDOR || "koko";
  const redirectSearchParams = new URLSearchParams(document.location.search)
    .get("redirect")
    ?.split("?")[1];

  const vendorParam =
    new URLSearchParams(redirectSearchParams ?? document.location.search).get(
      "vendor"
    ) || DEFAULT_VENDOR;

  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    const vendorUrl = process.env.REACT_APP_VENDOR_LOOKUP_URL || "";
    if (vendorUrl === "") {
      console.error("Vendor URL not defined");
    }
    fetch(`${vendorUrl}?vendor=${vendorParam}`)
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setVendor(response.vendor);
          setCSSVariables(response.vendor.theme);
        }
      });
  }, [vendorParam, redirectSearchParams]);

  const { Provider } = VendorContext;

  const handlePhotoUpload = (
    e: React.ChangeEvent<HTMLInputElement>
  ): boolean => {
    if (e.target.files && e.target.files.length > 0) {
      setPhotoFile(e.target.files[0]);
      return true;
    }
    return false;
  };

  const handleResponse = (response: BikeResult): void => {
    setSizingResults(response);
  };

  const handleBikeSelection = (bike: Bike) => {
    setSelectedBike(bike);
  };

  const handleEmailSent = (sent: boolean) => {
    setEmailSent(sent);
  };

  return (
    <Provider value={vendor}>
      <div className="app">
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <ProtectedRoute path="/getcard" vendorParam={vendorParam}>
              <GetCard />
            </ProtectedRoute>
            <ProtectedRoute path="/photoinstructions" vendorParam={vendorParam}>
              <PhotoInstructions onPhotoUpload={handlePhotoUpload} />
            </ProtectedRoute>
            <ProtectedRoute path="/reviewpicture" vendorParam={vendorParam}>
              <ReviewPicture
                photoFile={photoFile}
                onPhotoUpload={handlePhotoUpload}
                onResults={handleResponse}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/showbikematch" vendorParam={vendorParam}>
              <ShowBikeMatch
                sizingResults={sizingResults}
                handleBikeSelection={handleBikeSelection}
                handleEmailSent={handleEmailSent}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/viewbike" vendorParam={vendorParam}>
              <ViewBike
                selectedBike={selectedBike}
                emailSent={emailSent}
                sizingResults={sizingResults}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/error" vendorParam={vendorParam}>
              {/* TODO: this also needs to take in error response from backend */}
              <PhotoError
                photoFile={photoFile}
                onPhotoUpload={handlePhotoUpload}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/howitworks" vendorParam={vendorParam}>
              <HowItWorks />
            </ProtectedRoute>

            <ProtectedRoute path="/demo" vendorParam={vendorParam}>
              <DemoForm />
            </ProtectedRoute>
            <Route path="/">
              <Login vendorParam={vendorParam} />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </Provider>
  );
};

export default App;
