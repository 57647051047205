import React from "react";
import "App.css";

interface SubmitButtonProps {
  label: string;
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  inverted?: boolean;
}

const SubmitButton = ({ label, handleSubmit, inverted }: SubmitButtonProps) => {
  return (
    <button
      className={`${inverted ? "submitButtonInverted" : "submitButton"}`}
      onClick={handleSubmit}
    >
      {label}
    </button>
  );
};

export default SubmitButton;
